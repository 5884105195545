.linguagemSelect{
    border-radius: 7px;
    font-size: 15px;
    color: rgb(137, 189, 235);
    font-weight: 600;
    margin-right: 1rem;
}

.linguagemOptions{
    color: black;
    font-weight: 500;
    text-align: center; /* para firefox */
    text-align-last: center; /* para chrome */
    background-color: aliceblue;
}

.flag{
    border-radius: 7px;
}

.border{
    border: 1px solid #ccc;
}

.rdn-control {
    border: 2px solid #ccc!important;
    border-radius: 5px!important;
}

.rdn-drop {
    border: 2px solid #ccc!important;
    border-radius: 5px!important;
}
