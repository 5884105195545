table { border-collapse:collapse; border-spacing:0; empty-cells:show }
td, th { vertical-align:top; font-size:12pt;}
h1, h2, h3, h4, h5, h6 { clear:both;}
ol, ul { margin:0; padding:0;}
li { list-style: none; margin:0; padding:0;}
/* "li span.odfLiEnd" - IE 7 issue*/
li span { clear: both; line-height:0; width:0; height:0; margin:0; padding:0; }
span.footnodeNumber { padding-right:1em; }
span.annotation_style_by_filter { font-size:95%; font-family:Arial; background-color:#fff000;  margin:0; border:0; padding:0;  }
span.heading_numbering { margin-right: 0.8rem; }* { margin:0;}
.gr1 { 
  font-size:11pt;
  font-family:Calibri;
  writing-mode:horizontal-tb;
  direction:ltr; 
  vertical-align:middle; 
  text-align:center; 
}
.Header { 
  font-size:11pt; 
  line-height:115%; 
  margin-bottom:0.353cm; 
  margin-top:0cm; 
  text-align:left ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr;
}
.P1 { 
  font-size:11pt; 
  line-height:115%; 
  margin-bottom:0.353cm; 
  margin-top:0cm; 
  text-align:center ! important; 
  font-family:Calibri; writing-mode:horizontal-tb; direction:ltr;
}
.P10 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:center ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  font-weight:bold; 
}
.P11 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  font-weight:bold; 
}
.P13 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  font-weight:bold;
}
.P15 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
}
.P16 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
}
.P18_borderStart { 
  font-size:11pt; 
  line-height:100%; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  background-color:#ffff00; 
  padding-bottom:0cm; 
  border-bottom-style:none; 
}
.P18 { 
  font-size:11pt; 
  line-height:100%; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  background-color:#ffff00; 
  padding-bottom:0cm; 
  padding-top:0cm; 
  border-top-style:none; 
  border-bottom-style:none; 
}
.P18_borderEnd { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  background-color:#ffff00; 
  padding-top:0cm;  
  border-top-style:none;
}
.P2 { 
  font-size:12pt; 
  line-height:115%; 
  margin-bottom:0.353cm; 
  margin-top:0cm; 
  text-align:center ! important; 
  font-family:Arial Narrow; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  margin-left:-0.25cm; 
  margin-right:-0.252cm; 
  text-indent:0cm; 
  color:#808080; 
}
.P20 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  color:#ff0000; 
}
.P21 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  color:#ff0000; 
}
.P22_borderStart { 
  font-size:11pt; 
  line-height:100%; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  background-color:#ffff00; 
  padding-bottom:0cm;  
  border-bottom-style:none; 
}
.P22 { 
  font-size:11pt; 
  line-height:100%; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  background-color:#ffff00; 
  padding-bottom:0cm; 
  padding-top:0cm;  
  border-top-style:none; 
  border-bottom-style:none; 
}
.P22_borderEnd { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  background-color:#ffff00; 
  padding-top:0cm;  
  border-top-style:none;
}
.P23 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:center ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
}
.P24 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  margin-left:0cm; 
  margin-right:0cm; 
  text-indent:1.249cm; 
}
.P25 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  margin-left:0cm; 
  margin-right:0cm; 
  text-indent:1.249cm; 
}
.P26 { 
  color:#000000; 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  vertical-align:top; 
  writing-mode:horizontal-tb; 
  direction:ltr; }
.P27 { 
  color:#000000; 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  vertical-align:top; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
}
.P28 { 
  color:#000000; 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  vertical-align:top; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
}
.P29 { 
  color:#000000; 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  vertical-align:top; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
}
.P30 { 
  color:#000000; 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  vertical-align:top; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
}
.P31 { 
  color:#000000; 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  vertical-align:top; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
}
.P32 { 
  color:#000000; 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  vertical-align:top; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
}
.P33 { 
  color:#000000; 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  vertical-align:top; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  margin-left:1.27cm; margin-right:0cm; text-indent:0cm; }
.P3 { 
  font-size:11pt; 
  font-family:Calibri;  
  writing-mode:horizontal-tb; 
  text-align: center ! important; 
}
.P6 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-left: 1rem; 
  margin-right:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  text-indent:0cm; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
}
.P7 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; }
.P8 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:justify ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; }
.P9 { 
  font-size:11pt; 
  line-height:100%; 
  margin-bottom:0cm; 
  margin-top:0cm; 
  text-align:left ! important; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; 
  font-weight:bold; 
}
.Standard { font-size:11pt; 
  font-family:Calibri; 
  writing-mode:horizontal-tb; 
  direction:ltr; margin-top:0cm; 
  margin-bottom:0.353cm; 
  line-height:115%; 
  text-align:left ! important; 
}
.Internet_20_link { color:#0000ff; text-decoration:underline; }
.T11 { color:#ff0000; }
.T12 { color:#ff0000; }
.T14 { color:#ff0000; }
.T15 { color:#ff0000; }
.T17 { font-weight:bold; }
.T18 { font-weight:bold; }
.T2 { font-weight:bold; }
.T21 { font-weight:bold; }
.T22 { font-weight:bold; }
.T25 { font-style:italic; }
.T26 { font-style:italic; }
.T3 { font-weight:bold; }
.T6 { font-weight:bold; }